import * as React from "react"
import Button from "../Button/Button"
import { ContactStyles } from "./ContactStyles"

const Contact = () => {
  return (
    <ContactStyles className="section">
      <form name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <input placeholder="お名前をご入力ください" type="text" name="name" />
        <input placeholder="Emailをご入力ください" type="email" name="email" />
        <textarea
          placeholder="お問い合わせ内容をご入力ください"
          name="message"
          rows="8"
        ></textarea>
        <Button text="送信" />
      </form>
    </ContactStyles>
  )
}

export default Contact
