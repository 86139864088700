import * as React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Contact from "../components/Contact/Contact"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"

const contact = () => {
  return (
    <>
      <Seo title="Contact" />
      <Layout>
          <SimpleBanner title="お問い合わせ">
            <StaticImage
              className="banner__image"
              src="../../static/top-02-1920-1280.jpg"
              alt="Apple Macbook Dark"
            />
        </SimpleBanner>
        <Contact />
      </Layout>
    </>
  )
}


<form name="contactform" method="post" data-netlify="true" action="/jp/contact-thanks">
<input type="hidden" name="form-name" value="contactform" />
<div className="Form">
  <div className="Form-Item">
    <p className="Form-Item-Label">
      <span className="Form-Item-Label-Required">必須</span>組織名
    </p>
    <input type="text" className="Form-Item-Input" name="campany" id="campany" placeholder="例）学校法人プログラミング学園" />
  </div>
  <div className="Form-Item">
    <p className="Form-Item-Label">
      <span className="Form-Item-Label-Required">必須</span>所属
    </p>
    <input type="text" className="Form-Item-Input" name="unit" id="unit" placeholder="例）プログラミング教育課" />
  </div>
  <div className="Form-Item">
    <p className="Form-Item-Label">
      <span className="Form-Item-Label-Required">必須</span>氏名
    </p>
    <input type="text" className="Form-Item-Input" name="name" id="name" placeholder="例）山田太郎" />
  </div>
  <div className="Form-Item">
    <p className="Form-Item-Label">
      <span className="Form-Item-Label-Required">必須</span>電話番号
    </p>
    <input type="text" className="Form-Item-Input" name="tel" id="tel" placeholder="例）000-0000-0000" />
  </div>
  <div className="Form-Item">
    <p className="Form-Item-Label">
      <span className="Form-Item-Label-Required">必須</span>メールアドレス
    </p>
    <input type="text" className="Form-Item-Input" name="mail" id="mail" placeholder="例）example@proggakuen.com" />
  </div>

  <div className="Form-Item">
    <p className="Form-Item-Label isMsg">
      <span className="Form-Item-Label-Required">必須</span>お問い合わせ内容
    </p>
    <textarea className="Form-Item-Textarea" defaultValue={""} name="message" id="message"></textarea>
  </div>
</div>
<button type="submit" className="Form-Btn">送信する</button>
</form>


export default contact
